var generic = generic || {};
var site = site || {};
var Mustache = Mustache || {};
var IScroll = IScroll || {};

site.account = site.account || {};
site.signin = site.signin || {};
site.userInfoCookie = site.userInfoCookie || {};
site.userInfoCookie.getValue = site.userInfoCookie.getValue || function () {
  return '';
};

(function ($) {
  Drupal.behaviors.signIn = {
    attach: function (context) {
      var signedIn = site.userInfoCookie.getValue('signed_in');
      var forceReturn = false;
      var returnURL = null;

      if (signedIn === '0') {
        signedIn = false;
      }

      // Determine if user has signed in before by looking at the persistent
      // user cookie.
      var persistentCookie = Drupal.ELB.getJSONCookie('persistent_user_cookie');
      // @TODO: get email address too so we can put it in the input field
      var firstName = persistentCookie.first_name;
      var firstTime = persistentCookie.first_time;
      var userDetected = !!firstName;
      var $overlayTemplate = $('#signin-overlay-template', context);
      var overlayContent = $overlayTemplate.html();
      var $triggerButton = $('.page-utilities__account-button, .js-launch-account, .field-mobile-menu .sign-in---my-account', context);
      // Determine the state of the overlay to show:
      var signInOverlayState = $.cookie('signInOverlayState', { path: '/' });

      // Delete the cookie immediately (apparently these are both needed?):
      $.cookie('signInOverlayState', null, { path: '/' });
      $.cookie('signInOverlayState', null);

      var isMobile = !$('body').hasClass('device-pc');
      var $signInPage = $('.sign-in-page', context);
      var isSignInPage = $signInPage.length;
      var colorboxSettings = {
        html: overlayContent,
        className: 'signin-overlay-wrapper',
        width: '100%',
        maxWidth: '1022px',
        fixed: true
      };

      if (isMobile) {
        colorboxSettings.top = '0px';
        colorboxSettings.height = '1000px';
      }

      // User greeting and login/logout link below Account button
      if (userDetected && !isMobile) {
        if (signedIn) {
          $('.user-logged-in').show();
          $('.user-logged-out').hide();
        } else {
          $('.user-logged-out').show();
          $('.user-logged-in').hide();
        }
      }

      function _launchOverlay(forceReturn, returnURL) {
        $.colorbox(colorboxSettings);

        var $overlay = $('.signin-overlay-wrapper .sign-in-component');

        // Redirect back to the current page
        // var returnURL = '?RETURN_URL=' + window.location.pathname;
        // Generally only registration sends you back to your last page, but
        // there are some cases where sign in can (i.e., 'Save to Profile' in the
        // Foundation Finder)
        if (forceReturn) {
          $('form', $overlay).each(function () {
            if (!$('input[name=RETURN_URL]', this).length) {
              $(this).append('<input type="hidden" name="RETURN_URL" value="" />');
            }
          });
        }
        returnURL = returnURL || window.location.pathname + window.location.search;

        $('input[name=RETURN_URL]', $overlay).val(returnURL);
        $('.sign-in-component__form--registration input[name=RETURN_URL]', $overlay).val('/account/index.tmpl');
        _initForm($overlay);

        // Init selectboxes for desktop:
        if (!isMobile) {
          $('.selectbox', $overlay).selectBox();
          // Apply global js text input behavior:
          Drupal.behaviors.formTextInputs.attach($('.signin-overlay-wrapper'));
        }
      }

      function _getRutLength($regRut1) {
        return $regRut1.val().replace(/[^a-z0-9\s]/gi, '').length;
      }

      function _checkRut($regRut1, error_messages) {
        var rut = $regRut1[0];
        var str = $.trim(rut.value);
        var addition = 0;
        var multiple = 2;
        var value = str.replace(/[^a-z0-9\s]/gi, '');
        var body = value.slice(0, -1);
        var dv = value.slice(-1).toUpperCase();
        var bodyLength = body.length;

        if (bodyLength < 7) {
          $regRut1.addClass('error');
          rut.setCustomValidity(error_messages.error_message_invalid_rut);

          return false;
        }

        for (var i = 1; i <= bodyLength; i++) {
          var index = multiple * value.charAt(bodyLength - i);

          addition = addition + index;
          multiple < 7 ? multiple = multiple + 1 : multiple = 2;
        }

        var expectedDv = 11 - (addition % 11);

        dv = (dv == 'K') ? 10 : dv;
        dv = (dv == 0) ? 11 : dv;

        if (expectedDv !== parseInt(dv)) {
          $regRut1.addClass('error');
          rut.setCustomValidity(error_messages.error_message_invalid_rut);

          return false;
        } else {
          rut.setCustomValidity('');
          $regRut1.removeClass('error');
        }
      }

      function _formatRut($regRut1) {
        var length = _getRutLength($regRut1);

        $.mask.definitions['A'] = '[kK0-9]';
        switch (length) {
          case 8:
            $regRut1.mask('9?.999?.999?-A', { autoclear: false });
            break;
          default:
            $regRut1.mask('99?.999?.999?-A', { autoclear: false });
        }
      }

      function _setErrorsFromForm(formName, action) {
        $(`${formName} :input`).each(function () {
          var input = $(this);

          action === 'add'
            ? input.addClass('error')
            : input.removeClass('error');
        });
      }

      function _validateInputFields($error_messages_list_ul, fields) {
        var self = this;

        self.hasError = false;
        fields.forEach(function (field) {
          if (field.object.val() === '' || (field.name === 'policy' && field.object[0].checked === false)) {
            if ($error_messages_list_ul.has($(`[id="required.${field.name}"]`)).length === 0) {
              $error_messages_list_ul.append(`<li id="required.${field.name}">${field.error_message}</li>`);
              field.name === 'policy' ? field.object.parent().find('label, a').addClass('error') : field.object.addClass('error');
            }
            self.hasError = true;
          } else {
            $('[id="required.' + field.name + '"]', $error_messages_list_ul).remove();
            field.name === 'policy' ? field.object.parent().find('label, a').removeClass('error') : field.object.removeClass('error');
          }
        });

        return self.hasError;
      }

      function _initForm($wrapper) {
        var $errorMessageContainer = $('#signin-error-text');

        $('.sign-in-component', $signInPage).show();
        var $registerForm = $('.sign-in-component__form--registration', $wrapper);
        var $submitButton = $('.form-submit', $registerForm);
        var $signInForm = $('.sign-in-component__form--sign-in', $wrapper);
        var $showPass = $('input[name=SHOW_PASSWORD]', $wrapper);
        var $name = $('input[name=FIRST_NAME]', $registerForm);
        var $policy = $('input[name=ACCEPTED_PRIVACY_POLICY]', $registerForm);
        var $pass = $('input[type=password]', $registerForm);
        var $regEmailAddress = $('input[name=PC_EMAIL_ADDRESS]', $registerForm);
        var $signInEmailAddress = $('input[name=EMAIL_ADDRESS]', $signInForm);
        var $regRut = $('input[name=NATIONAL_ID]', $registerForm);
        var $regRut1 = $('input[name=NATIONAL_ID1]', $registerForm);
        var $regToken = $('input[name=_TOKEN]', $registerForm);
        var $error_messages_list = $('ul.error_messages').find('li');
        var $error_messages_list_ul = $('[id=form--errors--registration]', $wrapper);
        var error_message_name = $('#error_no_name', $errorMessageContainer).html();
        var error_message_password = $('#error_no_password', $errorMessageContainer).html();
        var error_message_policy = $('#error_no_policy', $errorMessageContainer).html();
        var error_message_invalid_rut = $('#error_invalid_rut', $errorMessageContainer).html();
        var error_message_no_rut = $('#error_no_rut', $errorMessageContainer).html();
        var error_message_email = $('#error_invalid_email', $errorMessageContainer).html();
        var $forms = $('form', $wrapper);
        var feUserCartCookie;
        var signedInValue;
        var registrationFormName;
        var $form = $();

        $forms.on('submit', function () {
          $form = $(this);
          feUserCartCookie = $.cookie('FE_USER_CART');
          feUserCartCookie = feUserCartCookie?.split('&').map((item) => item.split(':')) || feUserCartCookie;
          signedInValue = feUserCartCookie?.find((item) => item[0] === 'signed_in');
          registrationFormName = $('input[name="_SUBMIT"]', $form).val();
          if (registrationFormName === 'registration_confirm' && parseInt(signedInValue[1], 10) === 0) {
            window.location.href = '/account/signin.tmpl?_SUBMIT=signout';

            return false;
          }
        });

        // Set the appropriate class on the outer container to tell css what to
        // display. By default we show the registration
        // form, but if the overlay state cookie indicates we just registered or
        // signed in, we show the relevant confirmation screen instead. Finally,
        // if the user's ever logged in on this machine we display the sign in
        // form by default.

        // First off, if there's an error in the form, and we're trying to show
        // a confirmation page, go back a step:
        if (isSignInPage && $('input.error, select.error', $wrapper).length) {
          if (signInOverlayState === 'register-confirm') {
            signInOverlayState = 'register';
          } else if (signInOverlayState === 'signin-confirm') {
            signInOverlayState = 'signin';
          }
        }

        if (isSignInPage && $('#account_lockout\\.\\.').is(':visible')) {
          if (signInOverlayState === 'signin-confirm') {
            signInOverlayState = 'signin';
          }
        }

        if (isSignInPage && $('#account_exists\\.\\.').is(':visible')) {
          $signInEmailAddress.val($regEmailAddress.val());
          $signInEmailAddress.focus();
          signInOverlayState = 'signin';
        }

        // Toggle the class:
        if (signInOverlayState === 'register' || signInOverlayState === null) {
          $wrapper.addClass('registration');
        } else if (signInOverlayState === 'register-confirm') {
          $wrapper.addClass('registration-confirmation');
        } else if (signInOverlayState === 'signin-confirm') {
          // $wrapper.addClass('sign-in-confirmation');
        } else if (signInOverlayState === 'signin' || userDetected) {
          $wrapper.addClass('sign-in');
        }

        // if user has registered before then show sign in form
        if ((firstTime === 0) && (signInOverlayState !== 'register-confirm') && userDetected) {
          $wrapper.addClass('sign-in');
        }

        if (signInOverlayState === 'register' && $('form[name="registration"] input.error').length > 0 || $('form[name="registration"] label.error').length > 0) {
          $wrapper.removeClass('sign-in');
        }

        // Remove any stray error classes that may have ended up on the hidden forms:
        $('form:hidden', $wrapper).find('input.error, select.error').removeClass('error');

        // Preprocess the form:

        $pass.each(function () {
          $(this).after('<div class="visible-pass-wrapper"><input class="visible-pass form-text" type="text" style="display: none;" /></div>');
          if (!isMobile) {
            Drupal.behaviors.formTextInputs.attach($('.visible-pass-wrapper'));
          }
        });
        var $visiblePass = $('.visible-pass', $wrapper);

        // Add the user's first name to the sign in confirmation screen header:
        if (firstName) {
          var $signInConfirmHeader = $('.sign-in-component__confirm--sign-in .sign-in-component__header', $wrapper);

          $signInConfirmHeader.text($signInConfirmHeader.text().replace('first_name', firstName));
        }

        if ($('.sign-in-component__fpw-link', $wrapper).length > 0) {
          site.signin.forgotPassword({
            resetPassword: true,
            emailNode: $('input#sign-in-component__EMAIL_ADDRESS', $wrapper),
            errorListNode: $('.signin-block__lost-pass-text', $wrapper),
            forgotPasswordLink: $('#forgot-password', $wrapper),
            forgotPasswordNote: $('p#forgot_pw_note', $wrapper)
          });
        }

        // Bind events:

        $showPass.on('change', function () {
          var show = $(this).is(':checked');

          $visiblePass.add($pass).toggle();
          if (show) {
            $('.visible-pass', $wrapper).each(function () {
              $(this).val($(this).parent().prev().val()).trigger('blur');
            });
          } else {
            $pass.each(function () {
              $(this).val($(this).next().children().first().val()).trigger('blur');
            });
          }
        });

        $regRut1.on('change', function () {
          $regRut.val($(this).val());
        });

        $regRut1.on('click', function () {
          $regRut.val($(this).val());
        });

        $regRut1.on('click focus mouseleave blur change paste', function () {
          _formatRut($regRut1);
        });

        if ($.cookie('csrftoken')) {
          $regToken.val($.cookie('csrftoken'));
        }

        // Prevent the sms form from submitting on the register-confirm overlay when the mobile number is blank
        if (signInOverlayState === 'register-confirm') {
          $('.sign-in-component__confirm-options, input[type=submit]').on('click', function () {
            var mobileNumber = $('#sign-in-component__SMSPROMO_MOBILE_NUMBER');

            if ($(mobileNumber).attr('value') === '') {
              $('input[type=hidden], [name=_SECONDARY_SUBMIT], [value=sms]').remove();
            }
          });
        }

        $submitButton.on('click', function () {
          var inputFields = [
            {
              name: 'name',
              object: $name,
              error_message: error_message_name
            },
            {
              name: 'email',
              object: $regEmailAddress,
              error_message: error_message_email
            },
            {
              name: 'password',
              object: $pass,
              error_message: error_message_password
            },
            {
              name: 'rut',
              object: $regRut1,
              error_message: error_message_no_rut
            },
            {
              name: 'policy',
              object: $policy,
              error_message: error_message_policy
            }
          ];

          _checkRut($regRut1, { error_message_invalid_rut: error_message_invalid_rut });

          if (_validateInputFields($error_messages_list_ul, inputFields)) {
            return false;
          }
        });

        $signInForm.add($registerForm).on('submit', function () {
          // Set the password field to what's in the visible password field if
          // show password is checked
          var showPass = $showPass.is(':checked');

          if (showPass) {
            $pass.each(function () {
              $(this).val($(this).next().children().first().val());
            });
          }

          // Set a cookie so we remember which form was submitted so we can
          // launch the relevant confirmation overlay on the next page load
          var cookieVal = $(this).hasClass('sign-in-component__form--sign-in') ? 'signin-confirm' : 'register-confirm';

          $.cookie('signInOverlayState', cookieVal, { path: '/' });
        });

        if (document.location.hostname.match(/\.ca/)) {
          // by default box should be unchecked for Canada
          $('input[name="PC_EMAIL_PROMOTIONS"]').removeProp('checked');
        }

        $('.signin-overlay__toggle-form a', $wrapper).on('click.signIn', function (event) {
          event.preventDefault();

          $error_messages_list_ul.empty();
          _setErrorsFromForm('.sign-in-component__form--registration', 'remove');
          $policy.parent().find('label, a').removeClass('error');

          $wrapper.toggleClass('sign-in');

          $error_messages_list.empty();
          _setErrorsFromForm('.sign-in-component__form', 'remove');

          if (document.location.hostname.match(/\.ca/)) {
            // by default box should be unchecked for Canada
            $('input[name="PC_EMAIL_PROMOTIONS"]').removeProp('checked');
          }
        });

        $('.sign-in-component__close', $wrapper).on('click.signIn', function (event) {
          event.preventDefault();
          $.colorbox.close();
          if (signInOverlayState === 'register-confirm' && signedIn) {
            $('.my-feed-drawer .drawer-formatter__trigger').trigger('mouseover');
          }
        });
      } // /End initForm()

      $triggerButton.on('click.signIn', function (event) {
        event.preventDefault();
        window.location = '/account/index.tmpl';
        if (site.facebook) {
          site.facebook.init();
        }
      });

      $('.bfeed-create-account').on('click.signIn', function (event) {
        event.preventDefault();
        var forceReturn = $(this).hasClass('js-launch-account--return');
        var returnURL = forceReturn ? $(this).attr('data-return-url') : null;
        var popupScroll = null;

        // If already signed in, this button works as a link to the account
        // landing.
        if (signedIn) {
          window.location = '/account/index.tmpl';
        } else if ($('.sign-in-component', context).length) { // If the form is already on the page, focus on the first element in it
          $('.sign-in-component', context).find('.form-text:visible').first().focus();
        } else {
          _launchOverlay(forceReturn, returnURL);
          site.facebook.init();
          if (generic.env.isIOS4) {
            popupScroll.destroy();
            popupScroll = null;
            popupScroll = new IScroll('#colorbox', { mouseWheel: true });
            setTimeout(function () {
              popupScroll.refresh();
            }, 500);
          }
        }
      });

      $('.bfeed-sign-in').on('click.signIn', function (event) {
        event.preventDefault();
        var forceReturn = $(this).hasClass('js-launch-account--return');
        var returnURL = forceReturn ? $(this).attr('data-return-url') : null;
        var popupScroll = null;

        // If already signed in, this button works as a link to the account
        // landing.
        if (signedIn) {
          window.location = '/account/index.tmpl';
        } else if ($('.sign-in-component', context).length) { // If the form is already on the page, focus on the first element in it
          $('.sign-in-component', context).find('.form-text:visible').first().focus();
        } else {
          _launchOverlay(forceReturn, returnURL);
          if (generic.env.isIOS4) {
            popupScroll.destroy();
            popupScroll = null;
            popupScroll = new IScroll('#colorbox', { mouseWheel: true });
            setTimeout(function () {
              popupScroll.refresh();
            }, 500);
          }
          $('.signin-overlay__toggle-form.signin-overlay__toggle-form--have-acount a').trigger('click');
        }
      });

      // Automatically launch the overlay if the cookie is set and we're not on
      // the dedicated sign in page.
      if (!isSignInPage) {
        // Disabling the sign in confirmation functionality since it was removed from the spec.
        // if ((signInOverlayState === 'register-confirm' || signInOverlayState === 'signin-confirm') && signedIn) {
        if (signInOverlayState === 'register-confirm' && signedIn) {
          _launchOverlay(forceReturn, returnURL);
        }
      }

      // Run initForm directly on the context. This will only really be useful
      // for the sign in page, where the form is already embedded.
      _initForm($('.sign-in-component', context));

      // Ensure this script doesn't break site if perlgem isn't running:
      if (typeof site !== 'undefined' && typeof site.userInfoCookie !== 'undefined') {
        // enabling to use first_name placeholder in CMS
        var $template = $('.user-login-state');
        var rendered = Mustache.render($template.html(), { first_name: firstName });

        $template.html(rendered);
        // if user clicks on 'Sign out' link reset recognized user related cookies
        $('.sign-out-link').each(function () {
          var $signOutLink = $(this);
          var signOutURL = $signOutLink.attr('href');

          $signOutLink.attr('href', signOutURL).on('click', function () {
            var domain = '.' + window.location.hostname.replace(
              /^.*(esteelauder\.)/,
              function (m, $1) {
                return $1;
              }
            );
            var cookieObj = $.parseJSON($.cookie('persistent_user_cookie', {
              path: '/',
              domain: domain
            }));

            cookieObj.first_name = null;
            $.cookie('persistent_user_cookie', JSON.stringify(cookieObj), {
              path: '/',
              domain: domain
            });
            $.cookie('persistent_user_last_purchase', null, { path: '/' });
            $.cookie('expandMyFeedTray', 0, { path: '/' });
          });
        });
      }
    }
  };
})(jQuery);
